@use "../../sass" as *;

main {
  background-color: $secondary-clr;
  color: #374151;
  height: 100vh;
}

.head {
  // display: flex;
  // align-items: center;
  position: relative;
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;

  a {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 16px;
    border: 1px solid #e5e7eb;
    color: #000;
    font-weight: 600;
    padding: 7px 14px;
    border-radius: 9px;
    display: inline-block;
    line-height: 1;

    & svg {
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      color: #fff;
      background-color: #000;
      border: 1px solid #000;
    }
  }
}

@media (width <= 720px) {
  span {
    display: none !important;
  }

  a {
    padding: 5px !important;
  }
}

.box {
  padding: 0 40px 40px;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media (width <= 500px) {
  h1 {
    font-size: 19px;
  }

  .box {
    height: calc(100% - 55px);
  }
}

h2 {
  padding: 16px;
  line-height: 2;
  background-color: #fff;
  width: 100%;
  max-width: 640px;
  margin: auto;
  margin-top: 40px;
  border: 1px solid #e5e7eb;
  font-size: 18px;
  color: #71717a;
  font-weight: 500;
  border-radius: 9px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  min-height: 70px;
  margin-bottom: 40px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border-bottom-left-radius: 9px;
    font-size: 18px;

    &:hover {
      color: #f9f9fa;
      background-color: #374151;
    }
  }
}

input {
  width: 100%;
  height: 54px;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #374151;
  border: none;
  outline: none;
}

.input-box {
  min-height: 54px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 9px;
  max-width: 640px;
  border: 1px solid #e5e7eb;
  background-color: #fff;
  padding-right: 16px;
  overflow: hidden;

  &:focus-visible {
    outline: 1px solid #97989b;
  }

  div {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
}
